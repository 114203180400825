import {takeEvery, put, call} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {Organization} from "@sense-os/goalie-js";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {getSessionId} from "../../auth/helpers/authStorage";
import {organizationActions} from "../redux/organizationActions";
import {authActions} from "../../auth/redux/authActions";
import {toastActions} from "../../toaster/redux/toastAction";
import organizationSDK from "../helpers/organizationSdk";
import {setUserShouldChooseOrg, shouldUserChooseNewOrg} from "../helpers/organizationHelper";
import loc from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";

const log = createLogger("organizationSaga", SentryTags.Organization);

function* handleJoinOrganization(action: ActionType<typeof organizationActions.joinOrganization.request>) {
	try {
		const token: string = yield call(getSessionId);
		const toastText: string = loc.formatMessage(strTranslation.USER_PROFILE.org.approve_phase_dialog.toast.success);
		const organization: Organization = action.payload.organization;
		yield apiCallSaga([organizationSDK, organizationSDK.joinOrganization], organization.id, token);
		yield put(organizationActions.joinOrganization.success());
		yield call(setUserShouldChooseOrg, false);
		yield put(toastActions.addToast({message: toastText, type: "info"}));

		// After user chose a new organisation, they should be able to continue working,
		// Not treated as an unaccepted therapist (e.g Show org review dialog, and update authUser data)
		// TODO: Remove this IF condition only when corona scale-up migration is done.
		if (!shouldUserChooseNewOrg()) {
			yield put(
				authActions.updateAuthUser({
					organization: {
						id: organization.id,
						name: organization.name,
						logo: organization.logo,
					},
				}),
			);
		}
	} catch (err) {
		log.captureException(err);
		const toastText: string = loc.formatMessage(strTranslation.USER_PROFILE.org.approve_phase_dialog.toast.error);
		yield put(organizationActions.joinOrganization.failure(err));
		yield put(toastActions.addToast({message: toastText, type: "error"}));
	}
}

export default function* () {
	yield takeEvery(getType(organizationActions.joinOrganization.request), handleJoinOrganization);
}
