import {AppState} from "../../ts/redux/AppState";
import {createSelector} from "reselect";
import {cacheCreateSelectorPerUserId} from "redux/utils";

const getOrganizationsState = (state: AppState) => state.organizations;

export const getSelectionDialogState = createSelector(getOrganizationsState, (state) => state.isSelectionDialogOpen);

export const getOrganizations = createSelector(getOrganizationsState, (state) => state.organizations);
export const getOrganizationById = cacheCreateSelectorPerUserId((orgId: number) =>
	createSelector(getOrganizationsState, (state) => state.organizations.find((org) => org.id === orgId)),
);

export const getOrganizationsGroups = createSelector(getOrganizationsState, (state) => state.organizationsGroups);

export const getNonGroupOrganizations = createSelector(getOrganizationsState, (state) => state.nonGroupOrganizations);

export const getAvailableOrganizations = createSelector(getOrganizationsState, (state) => state.availableOrganizations);

export const getLoadingState = createSelector(getOrganizationsState, (state) => state.loadingState);
export const getLoadOrganizationLoadingState = createSelector(
	getOrganizationsState,
	(state) => state.loadOrganizationLoadingState,
);
