import storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";
import {StorageBools} from "services/system/storage/StorageBools";
import {OrganizationGroup, OrganizationMemberStatus, UserOrganization} from "@sense-os/goalie-js";
import {AppConfig} from "../../ts/app/AppConfig";
import organizationSDK from "../../organizations/helpers/organizationSdk";
import analyticsService from "../../analytics/AnalyticsService";
import {AnalyticsEvent} from "../../analytics/AnalyticsEventTypes";
import {AvailableOrganization} from "../organizationsTypes";

/**
 * Forces user to join an org by setting a boolean value in localStorage.
 *
 * Every user who creates an account in NiceDay will always be recognised as a Client.
 * However, the web portal will be force the user to join an organisation even though this behavior is not permanent.
 *
 * See https://paper.dropbox.com/doc/179-Portal-for-Clients-Technical-Design--BAjn5FDufBMoAlu8td48xJoSAg-o0B7Ighu0mxLHTl4PyqmO#:uid=986665726362483170417366&h2=Registration-Activity
 */
export function setUserShouldChooseOrg(shouldJoin: boolean = true) {
	storage.write(StorageKeys.SHOULD_JOIN_ORG, shouldJoin ? StorageBools.TRUE : StorageBools.FALSE);
}

/**
 * Returns true if user should choose an organisation
 */
export function shouldUserChooseOrg(): boolean {
	const shouldJoinOrg = storage.read(StorageKeys.SHOULD_JOIN_ORG);

	return shouldJoinOrg && shouldJoinOrg === StorageBools.TRUE;
}

/**
 * This function is for supporting corona scale-up organization migration.
 * TODO: Remove these all functions below when the migration is done.
 */
export function setUserShouldChooseNewOrg(shouldJoin: boolean = true) {
	storage.write(StorageKeys.SHOULD_JOIN_NEW_ORG, shouldJoin ? StorageBools.TRUE : StorageBools.FALSE);
}

export function shouldUserChooseNewOrg(): boolean {
	const shouldJoinOrg = storage.read(StorageKeys.SHOULD_JOIN_NEW_ORG);
	return shouldJoinOrg && shouldJoinOrg === StorageBools.TRUE;
}

export async function isTempOrganizationMember(token: string, userId: number): Promise<boolean> {
	const userOrg: UserOrganization[] = await getAcceptedAndPendingOrganizations(token, userId);
	if (!userOrg) {
		return null;
	}

	// If member of Parnassia already have pending org request it should not showing
	// org selection dialog again when user do re-login.
	if (!havePendingOrgRequest(userOrg) && isParnassiaOrgMember(userOrg)) {
		setUserShouldChooseOrg();
		setUserShouldChooseNewOrg();
		analyticsService.trackEvent(AnalyticsEvent.SHOW_NEW_ORG_SELECTION_DIALOG, {isParnasiaOrgMember: true});
		return true;
	}
	return false;
}

/**
 * Returns `accepted` and `pending` user organizations
 *
 * @param token
 * @param userId
 */
export async function getAcceptedAndPendingOrganizations(token: string, userId: number) {
	return organizationSDK.getOrganizationsOfUser(userId, token, [
		OrganizationMemberStatus.ACCEPTED,
		OrganizationMemberStatus.PENDING,
	]);
}

// Check is user member of Parnassia organization.
export function isParnassiaOrgMember(userOrg: UserOrganization[]): boolean {
	return !!findParnassiaOrgMember(userOrg);
}

// Check is user have pending organization request.
export function havePendingOrgRequest(userOrg: UserOrganization[]): boolean {
	return userOrg.some(({status}) => status === OrganizationMemberStatus.PENDING);
}

// Find Parnassia organization in given user organizations
export function findParnassiaOrgMember(userOrg: UserOrganization[]): UserOrganization {
	return userOrg.find(({organization}) => AppConfig.CORONA_SCALEUP_ORGANIZATION.includes(organization.id));
}

// Convert given organization group list into available organizations
export function convertOrganizationGroupsIntoAvailableOrganizations(
	orgGroupList: OrganizationGroup[],
): AvailableOrganization[] {
	let availableOrganizations: AvailableOrganization[] = [];

	orgGroupList.forEach((orgGroup) => {
		availableOrganizations.push({
			id: orgGroup.id,
			name: orgGroup.name,
			isOrgGroup: true,
		});
	});

	return availableOrganizations;
}

// Sort given organizations by organization name
export function sortOrganizationsByOrgName(organizations: AvailableOrganization[]) {
	let orgs: AvailableOrganization[] = [...organizations];

	return orgs.sort((first: {name: string}, second: {name: string}) => {
		const firstOrgName = first.name.toLowerCase();
		const secondOrgName = second.name.toLowerCase();

		if (firstOrgName < secondOrgName) return -1;
		if (firstOrgName > secondOrgName) return 1;
		return 0;
	});
}
