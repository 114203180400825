import SenseError from "@sense-os/error";
import {
	UnauthorizedError,
	TooManyLoginAttemptsError,
	InvalidUsernamePasswordError,
	EmailIsNotEnteredError,
	EmailFormatError,
	UsernameIsNotEnteredError,
	UsernameIsNotASCIIError,
	UsernameIsTooLong,
	UsernameAlreadyExistsError,
	PasswordIsNotEnteredError,
	PasswordTooSimilarWithEmailError,
	PasswordTooSimilarWithFirstNameError,
	PasswordTooSimilarWithLastNameError,
	PasswordTooSimilarWithUsernameError,
	PasswordTooShortError,
	PasswordTooCommonError,
	PasswordMustContainUppercaseLowercaseError,
	PasswordMustContainNumericError,
	PasswordMustContainSpecialCharacterError,
	PasswordMustContainAlphabetError,
	InvalidPasswordError,
	NameLengthError,
	EmailAlreadyRegisteredError,
	InternalServerError,
	CannotCreateAccountError,
	TooManyRequestsError,
} from "@sense-os/goalie-js";
import strTranslation from "../../assets/lang/strings";
import {history} from "../../helpers/routerHistory";
import localization from "../../localization/Localization";
import {AuthUserRole} from "../authTypes";

/**
 * Returns AUTH error message based on Error object
 *
 * @param {Error} e
 */
export function convertErrorIntoErrorMessage(e: Error): string {
	if (!e) {
		return null;
	}

	if (e instanceof UnauthorizedError) {
		return localization.formatMessage(strTranslation.AUTH.error.wrong_creds);
	}
	if (e instanceof TooManyLoginAttemptsError) {
		return localization.formatMessage(strTranslation.AUTH.error.too_many_login_attempts);
	}
	if (e instanceof InvalidUsernamePasswordError) {
		return localization.formatMessage(strTranslation.AUTH.error.invalid_username_password);
	}
	if (e instanceof EmailIsNotEnteredError) {
		return localization.formatMessage(strTranslation.AUTH.error.email.is_not_entered);
	}
	if (e instanceof EmailFormatError) {
		return localization.formatMessage(strTranslation.AUTH.error.email.format);
	}
	if (e instanceof UsernameIsNotEnteredError) {
		return localization.formatMessage(strTranslation.AUTH.error.username.is_not_entered);
	}
	if (e instanceof UsernameIsNotASCIIError) {
		return localization.formatMessage(strTranslation.AUTH.error.username.format);
	}
	if (e instanceof UsernameIsTooLong) {
		return localization.formatMessage(strTranslation.AUTH.error.username.is_too_long);
	}
	if (e instanceof UsernameAlreadyExistsError) {
		return localization.formatMessage(strTranslation.AUTH.error.username.exists);
	}
	if (e instanceof PasswordIsNotEnteredError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.is_not_entered);
	}
	if (e instanceof PasswordTooSimilarWithEmailError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.too_similar_with_email);
	}
	if (e instanceof EmailAlreadyRegisteredError) {
		return localization.formatMessage(strTranslation.AUTH.error.email.already_registered);
	}
	if (e instanceof PasswordTooSimilarWithUsernameError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.too_similar_with_username);
	}
	if (e instanceof PasswordTooShortError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.too_short);
	}
	if (e instanceof PasswordTooCommonError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.too_common);
	}
	if (e instanceof PasswordMustContainUppercaseLowercaseError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.must_contain_uppercase_lowercase);
	}
	if (e instanceof PasswordMustContainNumericError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.must_contain_numeric);
	}
	if (e instanceof PasswordMustContainSpecialCharacterError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.must_contain_special_character);
	}
	if (e instanceof PasswordMustContainAlphabetError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.must_contain_alphabet);
	}
	if (e instanceof InvalidPasswordError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.error);
	}
	if (e instanceof NameLengthError) {
		return localization.formatMessage(strTranslation.AUTH.error.name.length);
	}
	if (e instanceof CannotCreateAccountError) {
		return localization.formatMessage(strTranslation.AUTH.error.email.already_registered);
	}
	if (e instanceof PasswordTooSimilarWithFirstNameError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.too_similar_with_firstName);
	}
	if (e instanceof PasswordTooSimilarWithLastNameError) {
		return localization.formatMessage(strTranslation.AUTH.error.password.too_similar_with_lastName);
	}
	if (e instanceof TooManyRequestsError) {
		return localization.formatMessage(strTranslation.AUTH.error.too_many_requests, {
			retryAfter: e.details.retryAfter,
		});
	}
	return localization.formatMessage(strTranslation.AUTH.error.other);
}

/**
 * Only capture exception to sentry when backend throws HTTP 500 error
 * or there's an uncaught exception in the code.
 * The others are not necessary to be reported to sentry.
 */
export function shouldReportToSentry(error: Error): boolean {
	const isThrownBySDK = error instanceof SenseError;
	const isServerError = error instanceof InternalServerError;
	return isServerError || !isThrownBySDK;
}

export function getTermAndConditionLink(locale: string = "nl", version: string = "2018-04-12") {
	return `https://legal.nice-day.nl/terms-and-agreements/${locale}/${version}`;
}

export function getPrivacyPolicyLink(locale: string = "nl", version: string = "2018-05-30") {
	return `https://legal.nice-day.nl/privacy-policy/${locale}/${version}`;
}

export const isClient = (role: AuthUserRole) => role === AuthUserRole.CLIENT;
export const isTherapist = (role: AuthUserRole) => role === AuthUserRole.THERAPIST;

/**
 * Returns true if 2FA is required when user tried to change the password from the login screen
 */
export function passwordResetOTPRequired() {
	if (!history.location?.search) {
		return false;
	}
	const urlParams = new URLSearchParams(history.location.search);
	const requireOTP = urlParams.get("require_2fa");
	if (!requireOTP || requireOTP.trim().toLowerCase() === "false") {
		return false;
	}

	return true;
}
