import {CallContact, UserRole as SDKUserRole} from "@sense-os/goalie-js";
import {DISC} from "IoC/DISC";
import {call, select} from "redux-saga/effects";
import {States} from "services/chat/video/States";
import {AuthUser, AuthUserRole} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import {Contact} from "../../contacts/contactTypes";
import {getContactById} from "../../contacts/redux/contactSelectors";

/**
 * Returns true if user can start a new call
 */
export function* canStartCall() {
	const callState = yield call(DISC.getVideoCallService().getCallState);
	return callState === States.INITIALIZED;
}

/**
 * Retrieve `CallContact` data from redux state
 *
 * @param userId
 */
export function* getCallContactInfoByUserId(userId: number) {
	const authUser: AuthUser = yield select(getAuthUser);
	const publicId = createPublicId();

	let callContact: CallContact;

	if (userId === authUser.id) {
		const {imageUrl, role, organization} = authUser;
		const userRole = role === AuthUserRole.CLIENT ? SDKUserRole.PATIENT : SDKUserRole.THERAPIST;
		callContact = {
			role: userRole,
			firstName: authUser.data.firstName,
			lastName: authUser.data.lastName,
			profilePicture: imageUrl,
			publicId,
			organization: organization
				? {
						id: organization.id,
						name: organization.name,
						logo: organization.logo,
				  }
				: undefined,
		};
	} else {
		const contact: Contact = yield select((state) => getContactById(state, userId));
		callContact = {
			role: contact.role,
			firstName: contact.firstName,
			lastName: contact.lastName,
			profilePicture: contact.image,
			publicId,
			organization: contact.organization
				? {
						id: contact.organization.id,
						name: contact.organization.name,
						logo: contact.organization.logo,
				  }
				: undefined,
		};
	}

	return callContact;
}

function createPublicId(): string {
	let result = "";
	const length = 12; // length of outcome public id.
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}
