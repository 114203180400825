import {Organization, OrganizationGroup} from "@sense-os/goalie-js";
import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {AvailableOrganization} from "../organizationsTypes";

export const organizationActions = {
	openSelectionDialog: createAction("organizationsActions/openSelectionDialog")(),

	setAvailableOrganizations: createAction(
		"organizationsActions/setAvailableOrganizations",
		(availableOrgs: AvailableOrganization[]) => ({availableOrgs}),
	)(),

	loadOrganizations: createAsyncAction(
		"organizationsActions/loadOrganizations",
		"organizationsActions/loadOrganizationsSuccess",
		"organizationsActions/loadOrganizationsFail",
	)<void, {organizations?: Organization[]}, Error>(),

	loadOrganizationsGroups: createAsyncAction(
		"organizationsActions/loadOrganizationsGroups",
		"organizationsActions/loadOrganizationsGroupsSuccess",
		"organizationsActions/loadOrganizationsGroupsFail",
	)<void, {organizationsGroups: OrganizationGroup[]}, Error>(),

	loadNonGroupOrganizations: createAsyncAction(
		"organizationsActions/loadNonGroupOrganizations",
		"organizationsActions/loadNonGroupOrganizationsSuccess",
		"organizationsActions/loadNonGroupOrganizationsFail",
	)<void, {nonGroupOrganizations: Organization[]}, Error>(),

	joinOrganization: createAsyncAction(
		"organizationsActions/joinOrganization",
		"organizationsActions/joinOrganizationSuccess",
		"organizationsActions/joinOrganizationFail",
	)<{organization: Organization}, void, Error>(),
};

export type OrganizationActionType = ActionType<typeof organizationActions>;
