import {organizationActions, OrganizationActionType} from "./organizationActions";
import {getType} from "typesafe-actions";
import analyticsService from "../../analytics/AnalyticsService";

const trackedActions = [
	organizationActions.loadOrganizations.request,
	organizationActions.loadOrganizations.success,
	organizationActions.loadOrganizations.failure,

	organizationActions.loadNonGroupOrganizations.request,
	organizationActions.loadNonGroupOrganizations.success,
	organizationActions.loadNonGroupOrganizations.failure,

	organizationActions.joinOrganization.request,
	organizationActions.joinOrganization.success,
	organizationActions.joinOrganization.failure,
].map(getType);

const properties = (action: OrganizationActionType) => {
	switch (action.type) {
		case getType(organizationActions.loadOrganizations.request):
		case getType(organizationActions.loadNonGroupOrganizations.request):
			return null;
		case getType(organizationActions.joinOrganization.request):
			return action.payload;
		default:
			return null;
	}
};

export const organizationEvents = analyticsService.trackReduxActions(trackedActions, properties);
